import {useDispatch} from 'react-redux';

import * as asyncActions from 'store/slices/common/asyncActions';

const useCommonActions = () => {
    const dispatch = useDispatch();

    return {
        login: () => dispatch(asyncActions.login()),
    };
};

export default useCommonActions;

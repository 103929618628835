import {createAsyncThunk} from '@reduxjs/toolkit';
import {message} from 'antd';

import {getFilters, getPagination} from 'pages/TrucksNearby/slice/selectors';
import {transformSearchParamsToRequestBody} from 'pages/TrucksNearby/utils';
import {ActionPagination, Filters} from 'pages/TrucksNearby/types';
import {setFilters, setPagination} from 'pages/TrucksNearby/slice';

import {getCommonAuth} from 'store/slices/common/selectors';

import * as requests from 'core/gateways/Truck/requests';
import {Truck} from 'core/entities/Truck/types';

import {Pagination} from 'types/Pagination';

import {AppState} from 'store';

const ERROR_DURATION = 0.5;

const searchTrucks = createAsyncThunk<{pagination: Pagination; items: Truck[]}, undefined, {state: AppState}>(
    'trucksNearby/searchTrucks',
    async (params, thunkAPI) => {
        const state = thunkAPI.getState();

        const pagination = getPagination(state);
        const filters = getFilters(state);

        const commonAuth = getCommonAuth(state);

        const transformedSearchParams = transformSearchParamsToRequestBody({filters, pagination});

        try {
            const {data} = await requests.fetchTrucksNearby(transformedSearchParams, commonAuth.accessToken);

            return data;
        } catch (error) {
            message.error('Something went wrong, place try again', ERROR_DURATION);
        }
    },
);

const searchTrucksByPlace = createAsyncThunk<void, Filters['place'], {state: AppState}>(
    'trucksNearby/searchTrucksByPlace',
    (place, thunkAPI) => {
        thunkAPI.dispatch(setPagination({page: 1}));
        thunkAPI.dispatch(setFilters({place}));
        thunkAPI.dispatch(searchTrucks());
    },
);

const searchTrucksByPagination = createAsyncThunk<void, ActionPagination, {state: AppState}>(
    'trucksNearby/searchTrucksByPagination',
    (pagination, thunkAPI) => {
        thunkAPI.dispatch(setPagination(pagination));
        thunkAPI.dispatch(searchTrucks());
    },
);

const searchTrucksByFilters = createAsyncThunk<void, Filters['additional'], {state: AppState}>(
    'trucksNearby/searchTrucksByFilters',
    (filters, thunkAPI) => {
        thunkAPI.dispatch(setFilters({additional: filters}));
        thunkAPI.dispatch(setPagination({page: 1}));
        thunkAPI.dispatch(searchTrucks());
    },
);

export {searchTrucks, searchTrucksByPlace, searchTrucksByPagination, searchTrucksByFilters};

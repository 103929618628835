import {createAsyncThunk} from '@reduxjs/toolkit';

import {loginRequest} from 'services/auth';

const login = createAsyncThunk('common/login', async () => {
    const {data} = await loginRequest();

    return {accessToken: data?.AuthenticationResult?.AccessToken};
});

export {login};

import toNumber from 'lodash/toNumber';

import {LENGTH_UNITS} from 'utils/data/units/length';

import {LengthValues} from 'types/LengthUnits';

export const FLOATING_POINT_TO_INCHES = 4;
export const CENTIMETER_IN_METER = 100;
export const INCHES_IN_METER = 39.37;
export const INCHES_IN_CM = 0.3937;
export const INCHES_IN_FOOT = 12;

const convertCentimeterToInch = (cm: number): number => cm * INCHES_IN_CM;
const convertMeterToInch = (m: number): number => m * INCHES_IN_METER;
const convertFootToInch = (ft: number): number => ft * INCHES_IN_FOOT;

const convertInchToCentimeter = (inch: number): number => inch / INCHES_IN_CM;

const convertInchToFoot = (inch: number): [number, number] => [
    Math.trunc(inch / INCHES_IN_FOOT),
    Number((((inch % INCHES_IN_FOOT) / INCHES_IN_FOOT) * INCHES_IN_FOOT).toFixed()),
];

const convertInchToMeter = (inch: number): [number, number] => [
    Math.trunc(inch / INCHES_IN_METER),
    (inch / INCHES_IN_METER) % 1 === 0
        ? 0
        : Number((((inch % INCHES_IN_METER) / INCHES_IN_METER) * CENTIMETER_IN_METER).toFixed()),
];

const convertFromInch = ({payload, unit}: {payload?: number; unit?: LengthValues[number]}): [number, number] => {
    const payloadAsNumber = toNumber(payload || 0);

    const foot: [number, number] = convertInchToFoot(payloadAsNumber);

    const getInch = (): [number, number] => {
        return [0, payloadAsNumber % 1 === 0 ? payloadAsNumber : Number(payloadAsNumber.toFixed())];
    };

    const getCentimeter = (): [number, number] => {
        return [0, Number(convertInchToCentimeter(payloadAsNumber).toFixed())];
    };

    const getFoot = (): [number, number] => {
        return foot[1] >= INCHES_IN_FOOT ? [foot[0] + 1, 0] : foot;
    };

    const getMeter = (): [number, number] => {
        return convertInchToMeter(payloadAsNumber);
    };

    const converters = {
        [LENGTH_UNITS.CENTIMETER.value]: (): [number, number] => getCentimeter(),
        [LENGTH_UNITS.METER.value]: (): [number, number] => getMeter(),
        [LENGTH_UNITS.FOOT.value]: (): [number, number] => getFoot(),
        [LENGTH_UNITS.INCH.value]: (): [number, number] => getInch(),
        DEFAULT: (): [number, number] => [0, 0],
    };

    const converter = unit ? converters[unit] || converters.DEFAULT : converters.DEFAULT;

    return converter();
};

const getLengthView = ({payload, unit}: {payload?: number; unit?: LengthValues[number]}): string => {
    const [firstItem, secondItem] = convertFromInch({payload, unit});

    const converters = {
        [LENGTH_UNITS.METER.value]: () => `${firstItem}m ${secondItem}cm`,
        [LENGTH_UNITS.FOOT.value]: () => `${firstItem}’${secondItem}”`,
        [LENGTH_UNITS.CENTIMETER.value]: () => `${secondItem}cm`,
        [LENGTH_UNITS.INCH.value]: () => `${secondItem}”`,
        DEFAULT: () => '000',
    };

    const converter = unit ? converters[unit] || converters.DEFAULT : converters.DEFAULT;

    return converter();
};

const convertToInch = ({payload, unit}: {payload?: number | string; unit?: LengthValues[number]}): number => {
    const payloadAsNumber = toNumber(payload || 0);

    const converters = {
        [LENGTH_UNITS.CENTIMETER.value]: () => convertCentimeterToInch(payloadAsNumber),
        [LENGTH_UNITS.METER.value]: () => convertMeterToInch(payloadAsNumber),
        [LENGTH_UNITS.FOOT.value]: () => convertFootToInch(payloadAsNumber),
        [LENGTH_UNITS.INCH.value]: () => payloadAsNumber,
        DEFAULT: () => 0,
    };

    const converter = unit ? converters[unit] || converters.DEFAULT : converters.DEFAULT;

    return converter();
};

export {convertFromInch, getLengthView, convertToInch};

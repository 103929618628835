import {createSlice, createEntityAdapter} from '@reduxjs/toolkit';

import {Registry} from 'types/Registry';

import {getRegistry} from './asyncActions';

const defaultState: Registry = {
    truckEquipments: [],
    truckStatuses: [],
    trailerTypes: [],
    crossBorders: [],
    citizenship: [],
    lengthUnits: [],
    weightUnits: [],
    truckTypes: [],
};

const loadsAdapter = createEntityAdapter();

export const registrySlice = createSlice({
    name: 'registry',
    initialState: loadsAdapter.getInitialState(defaultState),
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getRegistry.fulfilled, (state, action) => {
            state.truckEquipments = action.payload?.truckEquipments || [];
            state.truckStatuses = action.payload?.truckStatuses || [];
            state.trailerTypes = action.payload?.trailerTypes || [];
            state.crossBorders = action.payload?.crossBorders || [];
            state.citizenship = action.payload?.citizenship || [];
            state.lengthUnits = action.payload?.lengthUnits || [];
            state.weightUnits = action.payload?.weightUnits || [];
            state.truckTypes = action.payload?.truckTypes || [];
        });
    },
});

export default registrySlice.reducer;

import {createSlice, createEntityAdapter} from '@reduxjs/toolkit';

import {PROGRESS_STATUSES} from 'pages/TrucksNearby/constants';
import {Filters} from 'pages/TrucksNearby/types';

import {Truck} from 'core/entities/Truck/types';

import {Pagination} from 'types/Pagination';

import {searchTrucks} from './asyncActions';

type State = {
    status: typeof PROGRESS_STATUSES[keyof typeof PROGRESS_STATUSES];
    filters: Partial<Filters>;
    pagination: Pagination;
    trucks: Truck[] | null;
};

const defaultState: State = {
    pagination: {allItemsCount: 0, page: 1, pageCount: 1, perPage: 50},
    status: PROGRESS_STATUSES.FULFILLED,
    trucks: null,
    filters: {},
};

const loadsAdapter = createEntityAdapter();

export const listSlice = createSlice({
    name: 'trucksNearby',
    initialState: loadsAdapter.getInitialState(defaultState),
    reducers: {
        setPagination: (state, action) => {
            state.pagination = {...state.pagination, ...action.payload};
        },
        setFilters: (state, action) => {
            state.filters = {...state.filters, ...action.payload};
        },
    },
    extraReducers: (builder) => {
        builder.addCase(searchTrucks.fulfilled, (state, action) => {
            state.pagination = action.payload?.pagination || state.pagination;
            state.status = PROGRESS_STATUSES.FULFILLED;
            state.trucks = action.payload?.items || [];
        });
        builder.addCase(searchTrucks.pending, (state) => {
            state.status = PROGRESS_STATUSES.PENDING;
        });
        builder.addCase(searchTrucks.rejected, (state) => {
            state.status = PROGRESS_STATUSES.REJECTED;
        });
    },
});

const {setFilters, setPagination} = listSlice.actions;

export {setFilters, setPagination};

export default listSlice.reducer;

import toNumber from 'lodash/toNumber';

import {WeightValues} from 'types/WeightUnits';

import {WEIGHT_UNITS} from 'utils/data/units/weight';

export const FLOATING_POINT_LBS_TO_TON = 3;
export const FLOATING_POINT_LBS_TO_KG = 2;
export const FLOATING_POINT_TO_LBS = 4;
const KG_IN_LBS = 0.45359237;
const THOUSAND = 1000;

export const convertTonToLbs = (value: number): number => (value / KG_IN_LBS) * THOUSAND;
export const convertKgToLbs = (value: number): number => value / KG_IN_LBS;

export const convertLbsToTon = (lbs: number): number => (lbs * KG_IN_LBS) / THOUSAND;
export const convertLbsToKg = (lbs: number): number => lbs * KG_IN_LBS;

const convertFromLbs = ({payload, unit}: {payload?: number; unit?: WeightValues[number]}): string => {
    const payloadAsNumber = toNumber(payload || 0);

    const converters = {
        [WEIGHT_UNITS.TON.value]: () => convertLbsToTon(payloadAsNumber).toFixed(FLOATING_POINT_LBS_TO_TON),
        [WEIGHT_UNITS.KG.value]: () => convertLbsToKg(payloadAsNumber).toFixed(FLOATING_POINT_LBS_TO_TON),
        [WEIGHT_UNITS.LBS.value]: () => payloadAsNumber.toFixed(FLOATING_POINT_LBS_TO_TON),
        DEFAULT: () => '',
    };

    const converter = unit ? converters[unit] || converters.DEFAULT : converters.DEFAULT;

    return converter();
};

const getWeightView = ({payload, unit}: {payload?: number; unit?: WeightValues[number]}): string => {
    const convertedPayload = Number(convertFromLbs({payload, unit}));

    const converters = {
        [WEIGHT_UNITS.LBS.value]: () => `${convertedPayload} lbs`,
        [WEIGHT_UNITS.TON.value]: () => `${convertedPayload} t`,
        [WEIGHT_UNITS.KG.value]: () => `${convertedPayload} kg`,
        DEFAULT: () => '',
    };

    const converter = unit ? converters[unit] || converters.DEFAULT : converters.DEFAULT;

    return converter();
};

const convertToLbs = ({payload, unit}: {payload?: number | string; unit?: WeightValues[number]}): number => {
    const payloadAsNumber = toNumber(payload || 0);

    const converters = {
        [WEIGHT_UNITS.TON.value]: () => convertTonToLbs(payloadAsNumber),
        [WEIGHT_UNITS.KG.value]: () => convertKgToLbs(payloadAsNumber),
        [WEIGHT_UNITS.LBS.value]: () => payloadAsNumber,
        DEFAULT: () => 0,
    };

    const converter = unit ? converters[unit] || converters.DEFAULT : converters.DEFAULT;

    return converter();
};

export {convertFromLbs, getWeightView, convertToLbs};

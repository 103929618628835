import {TruckRequest} from 'core/entities/Truck/types';

import {convertToInch} from 'utils/measurements/length';
import {convertToLbs} from 'utils/measurements/weight';

import {Filters} from 'pages/TrucksNearby/types';

import {Pagination} from 'types/Pagination';

interface CalculateFilters {
    crossBordersAmount: number;
    trailerTypesAmount: number;
    citizenshipAmount: number;
    truckTypesAmount: number;
    equipmentsAmount: number;
    statusesAmount: number;
    payloadAmount: number;
    filtersAmount: number;
    dimsAmount: number;
}

export const calculateFilters = (filters: Filters['additional']): CalculateFilters => {
    const {
        truckEquipments: equipments,
        truckStatuses: statuses,
        crossBorders: borders,
        trailerTypes,
        citizenship,
        truckTypes,
        payload,
        dims,
    } = filters || {};

    const trailerTypesAmount = trailerTypes?.length || 0;
    const citizenshipAmount = citizenship?.length || 0;
    const truckTypesAmount = truckTypes?.length || 0;
    const equipmentsAmount = equipments?.length || 0;
    const statusesAmount = statuses?.length || 0;
    const crossBordersAmount = borders?.length || 0;

    const dimsAmount = dims?.width || dims?.length || dims?.height ? 1 : 0;
    const payloadAmount = payload?.weight ? 1 : 0;

    const filtersAmount =
        trailerTypesAmount +
        crossBordersAmount +
        citizenshipAmount +
        truckTypesAmount +
        equipmentsAmount +
        statusesAmount +
        payloadAmount +
        dimsAmount;

    return {
        trailerTypesAmount,
        crossBordersAmount,
        citizenshipAmount,
        truckTypesAmount,
        equipmentsAmount,
        statusesAmount,
        filtersAmount,
        payloadAmount,
        dimsAmount,
    };
};

export const transformSearchParamsToRequestBody = (params: {
    filters: Partial<Filters>;
    pagination: Pagination;
}): TruckRequest => {
    const {filters, pagination} = params;
    const {place, additional} = filters || {};

    const {length, width, height} = additional?.dims || {};
    const {weight} = additional?.payload || {};

    const transformedDims = {
        length: convertToInch({payload: length, unit: additional?.dims?.units}),
        height: convertToInch({payload: height, unit: additional?.dims?.units}),
        width: convertToInch({payload: width, unit: additional?.dims?.units}),
    };

    const transformedPayloadWeight = convertToLbs({payload: weight, unit: additional?.payload?.units});

    const maxDistance = place?.maxDistance || 0;

    const coordinates = {
        latitude: place?.location?.coords?.latitude || 0,
        longitude: place?.location?.coords?.longitude || 0,
    };

    const clearedWeight = transformedPayloadWeight ? {payloadWeight: transformedPayloadWeight} : {};
    const clearedDims =
        transformedDims.height || transformedDims.length || transformedDims.width ? {dims: transformedDims} : {};

    const transformedFilters = {
        radiusSearch: {coordinates, maxDistance},
        equipment: additional?.truckEquipments,
        crossBorders: additional?.crossBorders,
        trailerTypes: additional?.trailerTypes,
        citizenship: additional?.citizenship,
        statuses: additional?.truckStatuses,
        truckTypes: additional?.truckTypes,

        // measurements
        ...clearedWeight,
        ...clearedDims,
    };

    const transformedPagination = {
        perPage: pagination?.perPage,
        page: pagination?.page,
    };

    const requestBody = {
        pagination: transformedPagination,
        filter: transformedFilters,
    };

    return requestBody;
};

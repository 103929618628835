import {useDispatch} from 'react-redux';

import * as asyncActions from 'store/slices/registry/asyncActions';

const useRegistryActions = () => {
    const dispatch = useDispatch();

    return {
        getRegistry: () => dispatch(asyncActions.getRegistry()),
    };
};

export default useRegistryActions;

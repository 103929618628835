import {combineReducers} from 'redux';

import trucksNearbySlice from 'pages/TrucksNearby/slice';

import registrySlice from 'store/slices/registry';
import commonSlice from 'store/slices/common';

const rootReducer = combineReducers({trucksNearby: trucksNearbySlice, registry: registrySlice, common: commonSlice});

export default rootReducer;

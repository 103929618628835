import {createSlice, createEntityAdapter} from '@reduxjs/toolkit';

import {Common} from 'types/Common';

import {login} from './asyncActions';

const defaultState: Common = {
    auth: {
        accessToken: null,
    },
};

const commonAdapter = createEntityAdapter();

export const commonSlice = createSlice({
    name: 'common',
    initialState: commonAdapter.getInitialState(defaultState),
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(login.fulfilled, (state, action) => {
            state.auth.accessToken = action.payload?.accessToken || null;
        });
    },
});

export default commonSlice.reducer;

import React, {useEffect} from 'react';
import {useSpring} from 'react-spring';
import {BackTop, Layout} from 'antd';

import Router from 'routing';

import useRegistryActions from 'hooks/useRegistry/useRegistryActions';
import useCommonActions from 'hooks/useCommon/useCommonActions';

import CustomHeader from './components/CustomHeader';
import Particles from './components/Particles';

import styles from './styles/styles.module.scss';

const CONFIG = {mass: 10, tension: 550, friction: 140};

const Main: React.FC = () => {
    const [springStyles, springApi] = useSpring<{xy: [number, number]}>(() => ({xy: [0, 0], config: CONFIG}));
    const {getRegistry} = useRegistryActions();
    const {login} = useCommonActions();

    const initialize = async () => {
        await login();

        getRegistry();
    };

    useEffect(() => {
        initialize();

        // eslint-disable-next-line
    }, []);

    const onMouseMove = (event: React.MouseEvent<HTMLDivElement>): void => {
        springApi.start({xy: [event.clientX, event.clientY]});
    };

    return (
        <>
            <Layout className={styles.layout} onMouseMove={onMouseMove}>
                <Layout.Header className={styles.header}>
                    <CustomHeader />
                </Layout.Header>
                <Layout.Content className={styles.content}>
                    <Router />
                </Layout.Content>
            </Layout>
            <Particles springStyles={springStyles} />
            <BackTop />
        </>
    );
};

export default Main;

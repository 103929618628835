import React from 'react';

import logo from './images/cargo_alliance_logo.svg';

import styles from './styles/styles.module.scss';

const CustomHeader: React.FC = () => {
    return (
        <div className={styles.container}>
            <div className={styles.logo}>
                <a href="/">
                    <img src={logo} alt="img" />
                </a>
            </div>
        </div>
    );
};

export default CustomHeader;

import React, {Suspense} from 'react';
import {Route, Switch} from 'react-router-dom';

import Spinner from 'components/ui/Spinner';

const TrucksNearbyPage = React.lazy(() => import('../pages/TrucksNearby'));

export default (): JSX.Element => {
    return (
        <Suspense fallback={<Spinner />}>
            <Switch>
                <Route exact path="/" component={TrucksNearbyPage} />
                <Route component={() => <p>not found</p>} />
            </Switch>
        </Suspense>
    );
};

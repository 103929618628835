import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {persistStore} from 'redux-persist';
import {BrowserRouter} from 'react-router-dom';
import {PersistGate} from 'redux-persist/integration/react';

import App from './App';

import store from './store';

import 'antd/dist/antd.less';
import 'assets/scss/custom.scss';

const persistedStore = persistStore(store);

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistedStore}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </PersistGate>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
);

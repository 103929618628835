import React from 'react';
import {animated} from 'react-spring';
import {SpringValues} from '@react-spring/core/dist/declarations/src/types';

import styles from './styles/styles.module.scss';

const Particles: React.FC<{springStyles: SpringValues<{xy: [number, number]}>}> = (props) => {
    const {springStyles} = props;

    /* eslint-disable no-magic-numbers */
    const transform1 = (x: number, y: number): string =>
        `translate3d(${x / 150}px,${y / 150}px,0) rotate(${x / 400}deg)`;
    const transform2 = (x: number, y: number): string =>
        `translate3d(-${x / 200}px,${y / 170}px,0) rotate(-${x / 420}deg)`;
    const transform3 = (x: number, y: number): string =>
        `translate3d(-${x / 170}px,-${y / 120}px,0) rotate(${x / 380}deg)`;
    const transform4 = (x: number, y: number): string =>
        `translate3d(${x / 180}px,${y / 160}px,0) rotate(-${x / 440}deg)`;
    const transform5 = (x: number, y: number): string =>
        `translate3d(-${x / 190}px,-${y / 180}px,0) rotate(${x / 360}deg)`;
    /* eslint-enable no-magic-numbers */

    const transformedStyles1 = {transform: springStyles.xy.to(transform1)};
    const transformedStyles2 = {transform: springStyles.xy.to(transform2)};
    const transformedStyles3 = {transform: springStyles.xy.to(transform3)};
    const transformedStyles4 = {transform: springStyles.xy.to(transform4)};
    const transformedStyles5 = {transform: springStyles.xy.to(transform5)};

    return (
        <div className={styles.container}>
            <animated.div className={styles.element1} style={transformedStyles1} />
            <animated.div className={styles.element2} style={transformedStyles2} />
            <animated.div className={styles.element3} style={transformedStyles3} />
            <animated.div className={styles.element4} style={transformedStyles4} />
            <animated.div className={styles.element5} style={transformedStyles5} />
        </div>
    );
};

export default Particles;

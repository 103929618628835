import React from 'react';
import {Spin} from 'antd';

import styles from './styles/styles.module.scss';

const Spinner: React.FC = () => {
    return (
        <div className={styles.container}>
            <Spin size="large" />
        </div>
    );
};

export default Spinner;

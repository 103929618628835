import {createAsyncThunk} from '@reduxjs/toolkit';

import {getCommonAuth} from 'store/slices/common/selectors';

import {fetchRegistry} from 'services/registry';

const getRegistry = createAsyncThunk('registry/getRegistry', async (_, {getState}) => {
    const state = getState();
    const commonAuth = getCommonAuth(state as any);

    const {data} = await fetchRegistry({accessToken: commonAuth.accessToken});

    return data;
});

export {getRegistry};
